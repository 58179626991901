import React from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
import { google_api_key } from '../secret';

const containerStyle = {
  width: '800px',
  height: '500px'
};

function MyGoogleMap({ center , text }) {
  const handleMarkerClick = () => {
    // Construct Google Maps URL for directions
    const directionUrl = `https://www.google.com/maps/dir/?api=1&destination=${center.lat},${center.lng}`;
    // Open the URL in a new tab
    window.open(directionUrl, '_blank');
  };
  return (
    <LoadScript
      googleMapsApiKey={google_api_key}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={18}
      >
        <MarkerF position={center} visible= {true} label ={text} onClick={handleMarkerClick}/>
      </GoogleMap>
    </LoadScript>
  );
}

MyGoogleMap.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired
  }).isRequired
};

export default MyGoogleMap;
