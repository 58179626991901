import React from 'react';
import "../styles/contact.css"
import profile from "../assets/pp.png"

const ContactPage = () => {

    return (


<div className="contact-card">
  <div className="profile-section">
    <img src={profile} alt="Profile Picture" className="profile-picture"/>
  </div>
  <div className="description-section">
    <h2>Dr. Mounir Ezouhri</h2>
    <p>Chirurgien orthopédiste et traumatologue</p>
    <p><strong>Phone:</strong> +590 690 67 10 12</p>
    <p><strong>Email:</strong> mounir@ezouhri.fr</p>
  </div>
</div>
    )
}

export default ContactPage;
