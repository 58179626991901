import React from "react";
import "../styles/navbar2.css";
import logo from '../assets/logo.png';

const Navbar2 = () => {
  return (
    <nav className="navbar2">
      <a href="/" className="nav1-logo-link">
        <img src={logo} alt="Logo" className="nav2-logo" />
      </a>
      <a href="/" className="nav-item2">Bibliographie</a>
      <a href="/" className="nav-item2">Documents</a>
      <a href="/" className="nav-item2">Videos</a>
      <a href="/" className="nav-item2">Actualites</a>
      <a href="/contact" className="nav-item2">Contact</a>
    </nav>
  );
};

export default Navbar2;