import React, { useState } from "react";
import "../styles/navbar1.css";
import logo from '../assets/logo.png'; // replace with your logo path

const Navbar1 = () => {
  const [activeDropdown, setActiveDropdown] = useState({
    services: false,
    service1: false,
    service2: false,
    service3: false,
    service4: false,
    service5: false,
    service6: false
  });

  const toggleDropdown = (dropdown) => {
    setActiveDropdown({
      services: true,
      service1: false,
      service2: false,
      service3: false,
      service4: false,
      service5: false,
      service6: false,
      [dropdown]: !activeDropdown[dropdown]
    });
  };

  return (
    <nav className="navbar1">
      <a href="/" className="nav1-item">Formations</a>
      {/* Main Services Dropdown */}
      <div className="dropdown">
        <button className="dropbtn" onClick={() => toggleDropdown('services')}>
          Les Chirurgies {activeDropdown.services ? '▲' : '▼'}
        </button>
        <div className={`dropdown-content ${activeDropdown.services ? 'show' : ''}`}>
          {/* Nested Service 1 Dropdown */}
          <div className="dropdown">
            <button className="dropbtn" onClick={() => toggleDropdown('service1')}>
              Chirurgies Main & Poignet {activeDropdown.service1 ? '▲' : '▼'}
            </button>
            <div className={`dropdown-content ${activeDropdown.service1 ? 'show' : ''}`}>
              <a href="/main/page1">Fracture du scaphoide</a>
              <a href="/main/page2">Maladie de Quervain</a>
              <a href="/main/page3">COMPRESSION DU NERF ULNAIRE (NERF CUBITAL)</a>
              <a href="/main/page4">Kyste arthro-synovial</a>
              <a href="/main/page5">Le doigt et le pouce à ressaut</a>
              <a href="/main/page6">Maladie de Dupuytren</a>
              <a href="/main/page7">Mallet finger</a>
              <a href="/main/page8">SYNDROME DU CANAL CARPIEN</a>
              <a href="/main/page9">Rhizarthrose</a>
              <a href="/main/page10">Syndrome Algoneurodystrophique</a>
              <a href="/main/page11">Tendinite</a>

            </div>
          </div>
          {/* Nested Service 2 Dropdown */}
          <div className="dropdown">
            <button className="dropbtn" onClick={() => toggleDropdown('service2')}>
              Chirurgie de l'épaule {activeDropdown.service2 ? '▲' : '▼'}
            </button>
            <div className={`dropdown-content ${activeDropdown.service2 ? 'show' : ''}`}>
              <a href="/epaule/page1">RUPTURE DE LA COIFFE DES ROTATEURS DE L'EPAULE</a>
              <a href="/epaule/page1">INSTABILITE ANTERIEURE DE L'EPAULE</a>
              <a href="/epaule/page1">TENDINITE CALCIFIANTE DE L'EPAULE</a>
              <a href="/epaule/page1">L'ARTICULATION ACROMIO CLAVICULAIRE</a>
            </div>
          </div>
          {/* Nested Service 3 Dropdown */}
          <div className="dropdown">
            <button className="dropbtn" onClick={() => toggleDropdown('service3')}>
              Chirurgie du genou {activeDropdown.service3 ? '▲' : '▼'}
            </button>
            <div className={`dropdown-content ${activeDropdown.service3 ? 'show' : ''}`}>
              <a href="/genou/page1">Ligamentoplastie du genou</a>
              <a href="/genou/page2">Chirurgie méniscale</a>
              <a href="/genou/page3">Prothèse totale du genou</a>
              <a href="/genou/page4">Prothèse unicompartimentale</a>
              <a href="/genou/page5">Chirurgie cartilagineuse du genou</a>
              <a href="/genou/page6">L'ostéotomie tibiale de valgisation</a>
              <a href="/genou/page7">Reprise de prothèse du genou</a>
              <a href="/genou/page8">Recentrage rotulien</a>

            </div>
          </div>
          {/* Nested Service 4 Dropdown */}
          <div className="dropdown">
            <button className="dropbtn" onClick={() => toggleDropdown('service4')}>
              Chirurgie de la hanche {activeDropdown.service4 ? '▲' : '▼'}
            </button>
            <div className={`dropdown-content ${activeDropdown.service4 ? 'show' : ''}`}>
              <a href="/hanche/page1">Prothèse totale de la hanche</a>
              <a href="/hanche/page2">Arthrolyse de hanche</a>
              <a href="/hanche/page3">Arthroscopie de la hanche</a>
              <a href="/hanche/page4">Reprise de prothèse de hanche</a>
              <a href="/hanche/page5">Chirurgie conservatrice de la hanche</a>
            </div>
          </div>
          {/* Nested Service 5 Dropdown */}
          <div className="dropdown">
            <button className="dropbtn" onClick={() => toggleDropdown('service5')}>
              Chirurgie de la cheville {activeDropdown.service5 ? '▲' : '▼'}
            </button>
            <div className={`dropdown-content ${activeDropdown.service5 ? 'show' : ''}`}>
              <a href="/cheville/page1">Ligamentoplastie de cheville</a>
              <a href="/cheville/page2">Réparation du tendon d'achille</a>
            </div>
          </div>
          {/* Nested Service 6 Dropdown */}
          <div className="dropdown">
            <button className="dropbtn" onClick={() => toggleDropdown('service6')}>
              Chirurgie du coude {activeDropdown.service6 ? '▲' : '▼'}
            </button>
            <div className={`dropdown-content ${activeDropdown.service6 ? 'show' : ''}`}>
              <a href="/coude/page1">Arthrolyse du coude</a>
            </div>
          </div>
        </div>
      </div>

      <a href="/" className="nav1-logo-link">
        <img src={logo} alt="Logo" className="nav1-logo" />
      </a>

      <a href="/about" className="nav1-item">Videos</a>
      <a href="/contact" className="nav1-item">Documents</a>


    </nav>
  );
};

export default Navbar1;