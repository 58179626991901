import React from 'react';
import { useHistory } from 'react-router-dom';

import '../styles/home.css';
import logo from "../assets/logo.png"
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import MyGoogleMap from '../components/GoogleMap';

const HomePage = () => {
  const location1 = {
    lat: 16.241076894742510,
    lng: -61.58643846429820
  };
  const location2 = {
    lat: 16.233775275977465,
    lng: -61.52840239094027
  };

  const location3 = {
    lat: 16.235147791064218,
    lng: -61.537041427598794
  };

  const handleCardClick = (path) => {
    window.location.href = path; // Redirects to the given path
  };
  return (
<>
    <div className="banner">
    Ce site est en cours de developement, merci de votre patience.
  </div>
    <div className="homepage">


      <br></br>
      <section className="section">
        <h1 className="title">les Spécialités Chirurgicales</h1>
        <p className="description">
          De l'épaule au genou en passant par la hanche, la cheville et le coude, la chirurgie traumatologique et orthopédique ne propose pas les
          mêmes solutions ni les mêmes gestes. Elle s'adapte à chaque situation dans un souci de performance, de sécurité et de confort.</p>
        <a href="https://www.doctolib.fr/chirurgien-orthopediste/paris/mounir-ezouhri" className="link">Prendre Rendez-Vous Doctolib</a>
        <Row>
          <Col>
            <div className="card" onClick={() => handleCardClick('/hanche')}>
              <h1 className="titre">Chirurgie de la Hanche</h1>
              <p className="description">
                Arthrose de la hanche, pathologies intra-articulaires, descellement de prothèse… Chaque cas correspond à une chirurgie de la hanche spécifique
                avec pour objectif d'optimiser la mobilité et le confort articulaire.
              </p>
            </div>
          </Col>
          <Col>
            <div className="card" onClick={() => handleCardClick('/coude')}>
              <h1 className="titre">Chirurgie du coude</h1>
              <p className="description">
                Intervenant dans le cadre d'une raideur de l'articulation consécutive à un traumatisme ou à l'arthrose, la chirurgie du coude consiste à enlever les
                parties endommagées pour permettre une reconstruction naturelle de l'articulation...
              </p>


            </div>
          </Col>
          <Col>
            <div className="card" onClick={() => handleCardClick('/epaule')}>
              <h1 className="titre">Chirurgie de l'épaule</h1>
              <p className="description">
                La chirurgie de l'épaule propose des gestes spécifiques selon les maux dont l'articulation souffre : coiffe des rotateurs ou rupture des tendons de
                l'épaule, pose et reprise de prothèse, stabilisation de l'épaule…
              </p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="card" onClick={() => handleCardClick('/genou')}>
              <h1 className="titre">Chirurgie du genou</h1>
              <p className="description">
                Atteintes des ligaments ou des ménisques, arthrose, dysfonctionnement des rotules… Des pathologies typiques de la chirurgie du genou qui
                nécessitent des gestes thérapeutiques pour retrouver toute la fonctionnalité de l'articulation.
              </p>
            </div>
          </Col>
          <Col>
            <div className="card" onClick={() => handleCardClick('/cheville')}>
              <h1 className="titre">Chirurgie de la cheville</h1>

              <p className="description">
                Rupture du tendon d'Achille et entorses de cheville à répétition nécessitent une intervention chirurgicale de la cheville : celle-ci permet de réparer
                les tendons ou ligaments concernés pour récupérer une amplitude de mouvements idéale.
              </p>

            </div>
          </Col>
          <Col>
            <div className="card" onClick={() => handleCardClick('/main')}>
              <h1 className="titre">Chirurgie de la main</h1>
              <p className="description">
                Cette spécialité s'occupe de la main, du poignet et de l'avant-bras de façon globale et prend en charge les troubles, qu'ils soient d'origine
                traumatique, dégénérative ou congénitale.
              </p>

            </div>
          </Col>
        </Row>
        {/* <img src={logo} alt="Logo" className="nav1-logo" /> */}
      </section>
      <br></br>
      <br></br>

      <section className="section">
        <h1 className="title">les Spécialités Non-Chirurgicales</h1>
        <p className="description">
          Diverses disciplines médicales s'articulent autour de la chirurgie traumatologique telles que les traitements bio régénérant, la rééducation
          physique et sportive, l'endermothérapie ou l'exploration fonctionnelle de l'appareil locomoteur.
        </p>
        <a href="/" className="link">Voir toutes les spécialités non chirurgicales</a>
        <Row>
          <Col>
            <div className="card" onClick={() => handleCardClick('/')}>
              <h1 className="titre">Appareil locomoteur</h1>

            </div>
          </Col>
          <Col>
            <div className="card" onClick={() => handleCardClick('/')}>
              <h1 className="titre">Endermethorapie</h1>

            </div>
          </Col>
          <Col>
            <div className="card" onClick={() => handleCardClick('/')}>
              <h1 className="titre">La reeducation</h1>

            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="card" onClick={() => handleCardClick('/')}>
              <h1 className="titre">Podologie</h1>

            </div>
          </Col>
          <Col>
            <div className="card" onClick={() => handleCardClick('/main')}>
              <h1 className="titre">Dietetique</h1>
            </div>
          </Col>
          <Col>
            <div className="card" onClick={() => handleCardClick('/')}>
              <h1 className="titre">Bio regeneration</h1>

            </div>
          </Col>
        </Row>
      </section>

      <br></br>
      <br></br>
      <section className="section">
        <h1 className="title">Lieux de consultations</h1>
        <div className="map-container">
          <div className="info-panel">
            <h1 className="titre">Clinique les Eaux Claires</h1>
            <p className="description">
              <b>Jarry, Clinique les Eaux Claires</b>
              <br></br>
              Moudong Sud Baie Mahault,
              97122, Guadeloupe
            </p>

          </div>
          <div className="google-map">
            <MyGoogleMap center={location1} />
          </div>
        </div>

        <br></br>
        <br></br>

        <div className="map-container">
          <div className="info-panel">
            <h1 className="titre">Polyclinique De La Guadeloupe</h1>
            <p className="description">
              <b>Morne Jolivière, Rue de l'Institut Pasteur</b>
              <br></br>
              Les Abymes,
              97139, Guadeloupe
            </p>

          </div>
          <div className="google-map">
            <MyGoogleMap center={location2} />
          </div>
        </div>

        <br></br>
        <br></br>

        <div className="map-container">
          <div className="info-panel">
            <h1 className="titre">Cabinet Professionel</h1>
            <p className="description">
              <b>57 Centre Saint John pers</b>
              <br></br>
              Quai ferdinand depesseps,
              Point a Pitre,
              97110, Guadeloupe
            </p>

          </div>
          <div className="google-map">
            <MyGoogleMap center={location3} />
          </div>
        </div>

      </section>

      <br></br>
      <br></br>

      <footer className="footer">
        <p>© {new Date().getFullYear()} Mounir Ezouhri. All rights reserved.</p>
      </footer>

      <br></br>


    </div>
    </>

  );
};

export default HomePage;
