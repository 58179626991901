import React from 'react';
import '../../styles/genou.css';


const MainPageGenou = () => {
  return (
    <h1>Hello genou</h1>
  );
};

export default MainPageGenou;