import logo from './logo.svg';
import {BrowserRouter as Router,Routes,Route,Link} from "react-router-dom";
import './App.css';

import Navbar1 from "./components/Navbar1";
import Navbar2 from "./components/Navbar2";

import Home from "./pages/HomePage";

import Cheville from "./pages/Cheville/MainPage"
import Cheville1 from "./pages/Cheville/Page1"
import Cheville2 from "./pages/Cheville/Page2"


import Coude from "./pages/Coude/MainPage"
import Coude1 from "./pages/Coude/Page1"


import Epaule from "./pages/Epaule/MainPage"
import Epaule1 from "./pages/Epaule/Page1"
import Epaule2 from "./pages/Epaule/Page2"
import Epaule3 from "./pages/Epaule/Page3"
import Epaule4 from "./pages/Epaule/Page4"


import Genou from "./pages/Genou/MainPage"
import Genou1 from "./pages/Genou/Page1"
import Genou2 from "./pages/Genou/Page2"
import Genou3 from "./pages/Genou/Page3"
import Genou4 from "./pages/Genou/Page4"
import Genou5 from "./pages/Genou/Page5"
import Genou6 from "./pages/Genou/Page6"
import Genou7 from "./pages/Genou/Page7"
import Genou8 from "./pages/Genou/Page8"


import Hanche from "./pages/Hanche/MainPage"
import Hanche1 from "./pages/Hanche/Page1"
import Hanche2 from "./pages/Hanche/Page2"
import Hanche3 from "./pages/Hanche/Page3"
import Hanche4 from "./pages/Hanche/Page4"
import Hanche5 from "./pages/Hanche/Page5"


import Main from "./pages/Main/MainPage"
import Main1 from "./pages/Main/Page1"
import Main2 from "./pages/Main/Page2"
import Main3 from "./pages/Main/Page3"
import Main4 from "./pages/Main/Page4"
import Main5 from "./pages/Main/Page5"
import Main6 from "./pages/Main/Page6"
import Main7 from "./pages/Main/Page7"
import Main8 from "./pages/Main/Page8"
import Main9 from "./pages/Main/Page9"
import Main10 from "./pages/Main/Page10"
import Main11 from "./pages/Main/Page11"

import Bibliographie from "./pages/BibliographiePage"
import Documents from "./pages/DocumentsPage"
import Video from "./pages/VideoPage"
import Actualites from "./pages/ActualitesPage"
import Contact from "./pages/ContactPage"

function App() {
  return (
    <div className="App" >

      <Router>
          {/* <Navbar1/> */}
          <Navbar2/>
        <Routes>

            <Route path='/' element={<Home/>} />
            <Route path='/bibliographie' element={<Bibliographie/>} />
            <Route path='/documents' element={<Documents/>} />
            <Route path='/video' element={<Video/>} />
            <Route path='/actualites' element={<Actualites/>} />
            <Route path='/contact' element={<Contact/>} />
            

            <Route path='/main' element={<Main/>} />
            <Route path='/main/page1' element={<Main1/>} />
            <Route path='/main/page2' element={<Main2/>} />
            <Route path='/main/page3' element={<Main3/>} />
            <Route path='/main/page4' element={<Main4/>} />
            <Route path='/main/page5' element={<Main5/>} />
            <Route path='/main/page6' element={<Main6/>} />
            <Route path='/main/page7' element={<Main7/>} />
            <Route path='/main/page8' element={<Main8/>} />
            <Route path='/main/page9' element={<Main9/>} />
            <Route path='/main/page10' element={<Main10/>} />
            <Route path='/main/page11' element={<Main11/>} />

            <Route path='/epaule' element={<Epaule/>} />
            <Route path='/epaule/page1' element={<Epaule1/>} />
            <Route path='/epaule/page2' element={<Epaule2/>} />
            <Route path='/epaule/page3' element={<Epaule3/>} />
            <Route path='/epaule/page4' element={<Epaule4/>} />

            <Route path='/genou' element={<Genou/>} />
            <Route path='/genou/page1' element={<Genou1/>} />
            <Route path='/genou/page2' element={<Genou2/>} />
            <Route path='/genou/page3' element={<Genou3/>} />
            <Route path='/genou/page4' element={<Genou4/>} />
            <Route path='/genou/page5' element={<Genou5/>} />
            <Route path='/genou/page6' element={<Genou6/>} />
            <Route path='/genou/page7' element={<Genou7/>} />
            <Route path='/genou/page8' element={<Genou8/>} />
            
            <Route path='/hanche' element={<Hanche/>} />
            <Route path='/hanche/page1' element={<Hanche1/>} />
            <Route path='/hanche/page2' element={<Hanche2/>} />
            <Route path='/hanche/page3' element={<Hanche3/>} />
            <Route path='/hanche/page4' element={<Hanche4/>} />
            <Route path='/hanche/page5' element={<Hanche5/>} />

            <Route path='/cheville' element={<Cheville/>} />
            <Route path='/cheville/page1' element={<Cheville1/>} />
            <Route path='/cheville/page2' element={<Cheville2/>} />

            <Route path='/coude' element={<Coude/>} />
            <Route path='/coude/page1' element={<Coude1/>} />
        </Routes>
      </Router>
      </div>
  );
}

export default App;
